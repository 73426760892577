import styled from "styled-components";

export const StripeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  height: var(--stripe);
  margin-bottom: var(--sectionSpacing);

  > img {
    width: auto;
    height: 25px;
  }

  @media screen and (min-width: 1128px) {
    > img {
      width: auto;
      height: 52px;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: auto;
    height: 64px;
  }
`;

export const TextWrapper = styled.h2`
  margin: 0px 0px 0px 10px;
  padding: 0px;
  font-weight: lighter;
  font-size: var(--textL);
  color: var(--primary800);

  @media screen and (min-width: 1128px) {
    font-size: var(--textXL);
  }
`;

export default StripeWrapper;
