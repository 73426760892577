import { NextImage } from "../index";
import { StripeWrapper, LogoWrapper, TextWrapper } from "./styles";

export const Stripe = ({ logo, text }) => {
  return (
    <StripeWrapper>
      {logo && (
        <LogoWrapper>
          <NextImage media={logo} alt={text} />
        </LogoWrapper>
      )}
      {text && <TextWrapper>{text}</TextWrapper>}
    </StripeWrapper>
  );
};

export default Stripe;
